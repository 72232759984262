<template>
  <div id="app">
    <div id="nav">
      <Navbar v-if="this.$router.history.current.name != 'Template2'" />
    </div>
    <router-view/>
  </div>
</template>

<style lang="scss">
</style>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>