<template>
    <div>
  
        <b-alert v-model="failed" variant="danger" dismissible>
        <i class="far fa-times-circle"></i> {{ error }}
        </b-alert>

      <form @submit.prevent="submitForm" class="mt-20 md:w-1/2 mx-auto bg-white rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
        <h2 class="mb-6 text-3xl uppercase text-gray-600">Want an appointment?</h2>
        <div class="mb-4">
          <!-- <label class="block text-grey-darker text-sm font-bold mb-2" for="email">
            Email
          </label> -->
          <input class="bg-gray-200 shadow appearance-none border rounded w-full p-3 text-grey-darker" id="firstname" type="name"  v-model="firstName" placeholder="First Name" required>

          <input class="mt-10 bg-gray-200 shadow appearance-none border rounded w-full p-3 text-grey-darker" id="lastname" type="name"  v-model="lastName" placeholder="Last Name" required>

          <input class="mt-10 bg-gray-200 shadow appearance-none border rounded w-full p-3 text-grey-darker" id="email" type="email"  v-model="email" placeholder="Email" required>
        </div>
        <div class="my-6">
          <!-- <label class="block text-grey-darker text-sm font-bold mb-2" for="phone">
            Phone
          </label> -->
          <input v-model="phone"  class="bg-gray-200 shadow appearance-none border border-red rounded w-full p-3 text-grey-darker mb-3" id="phone" type="tel" placeholder="Phone Number">
          <!-- <input v-model="phone"  class="bg-gray-200 shadow appearance-none border border-red rounded w-full p-3 text-grey-darker mb-3" id="phone" type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" placeholder="Phone Number"> -->


          <p class="text-red text-xs italic">Please enter your phone number</p>
        </div>
        <div class="flex items-center justify-between">
          <!-- <button onclick="alert('Thank you! Please check your email')" class="bg-green-600 hover:bg-green-500 text-white font-bold py-2 px-4 rounded" type="button">
            Book Now
          </button> -->
          <input type="submit" value="Book Now"  class="bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded">
          <a class="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker" href="#">
            Save info for future payments
          </a>
        </div>
      </form>
    </div>
  </template>
<script>
require('dotenv').config();
  const Airtable = require('airtable')
  const base = new Airtable({ endpointUrl: 'https://api.airtable.com', apiKey: process.env.VUE_APP_AIRTABLE_API_KEY }).base(
    'appI0C1Xnm4YSUn9T'
  )
  
  export default {
    name: 'contact-form',
    data () {
      return {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        error: null
      }
    },
    methods: {
      submitForm () {
        /* eslint-disable no-unused-vars */
        if (
          this.firstName === null ||
          this.lastName === null ||
          this.email === null ||
          this.phone === null
        ) {
          this.showFailed()
        } else {
          base('ContactsTable').create(
            {
                'First Name': `${this.firstName}`,
                'Last Name': `${this.lastName}`,
                'Email': `${this.email}`,
                'Phone Number': `${this.phone}`
            },
            { typecast: true },
            (err, record) => {
              if (err) {
                this.showFailed(err)
                return
                /* eslint-enable no-unused-vars */
              }
              this.showSubmitted()
            }
          )
        }
      },
      showSubmitted () {
        this.firstName = null
        this.lastName = null
        this.phone = null
        this.email = null
      },
      showFailed (err) {
        // this.$swal('All fields are required. Please try again.')
        this.failed = true
        this.error = err
      }
    }
  }
  </script>
  