import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Template2 from '../views/Template2.vue'
import Template3 from '../views/Template3.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/api',
    name: 'Api',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Api.vue')
  },
  {
    path: '/temp2',
    name: 'Template2',
    component: Template2
  },
  {
    path: '/temp3',
    name: 'Template3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Template3
  },
  {
    path: '/*',
    component: 404
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
