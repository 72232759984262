<template>
  <div class="home">
    <main class="text-black dark:text-white bg-white dark:bg-gray-900 h-full">
    <!--/HERO-->
    <section class="md:grid md:grid-cols-2 p-10 overflow-y-hidden" style="height:700px">
      <div>
        <div>
          <HeaderTitle :msg="response.data.title[0].text"/>
          <p class="dark:text-yellow-50 mt-6 capitalize">{{ response.data.description[0].text }}</p>
        </div>
        <div class="mt-8 flex">
          <button class="px-8 md:px-16 py-5 bg-gray-200 dark:bg-yellow-200 dark:text-black">Book Now</button>
          <button class="ml-4 px-8 md:px-16 py-5 border-2 dark:border-yellow-50 dark:text-yellow-50">Read More</button>
        </div>
        <div class="mt-8 grid grid-cols-3">
          <div class="text-6xl">12</div>
          <div class="text-6xl">43</div>
          <div class="text-6xl">3</div>
        </div>
      </div>
      <img alt="brad" class="mt-8 md:mt-0 bg-gray-500 object-cover w-full md:mx-auto md:w-2/3 h-full max-h-80 md:max-h-fit" src="../assets/images/bw.png">
    </section>
    <!--HERO-->
    <hr class="m-10">

    <!--SERVICES-->
    <section class="text-center">
      <h2 class="text-6xl">Our Services</h2>
      <p class="mt-4">More niformation about the services we have</p>
    </section>

    <section id="gallery" class="p-10">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 h-full">
        <img alt="image" v-for="image in images" :key="image" class="w-full max-h-80 md:max-h-fit border-2 border-white h-full object-cover" :src="image">
      </div>
    </section>
    <!--/SERVICES-->
    </main>

    <Footer/>
    
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderTitle from '@/components/HeaderTitle.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    HeaderTitle, Footer
  },
  data() {
    return {
      // Initialize a "response" data property
      response: null,
      // This array holds the service-images for now (until we add them to prismic)
      images: [
        'https://images.unsplash.com/photo-1520338661084-680395057c93?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80',
        'https://images.unsplash.com/photo-1621607512022-6aecc4fed814?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80',
        'https://images.unsplash.com/photo-1618247140092-c9278ce7fc9f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1586&q=80'
      ]
    }
  },
  methods: {
    async getContent() {
      // Store the 'homepage' document from the Prismic API in a constant called 'response'
      const response = await this.$prismic.client.getSingle('homepage');
      // and assign the response to the "response" data property
      this.response = response;
    }
  },
  created() {
    // Call the method to get the prismic content
    this.getContent();
  }
}
</script>
