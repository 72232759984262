<template>
<div class="template-3">
  <div class="p-20">
    <p class="text-white">{{name}}</p>
    <h1 class="mb-8 text-green-500 text-3xl font-bold">{{ response.data.title[0].text }}</h1>
    <prismic-image
      :field="response.data.image"
      alt="this is an image"
      class="h-64"
    />
    <p class="mb-8 text-white">{{ response.data.description[0].text }}</p>
    <pre class="text-white" id="app" v-html="JSON.stringify(response, null, 2)"></pre>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
// default is an object
// data is a function/method that returns an object
// methods is an object key that contains multiple functions
export default {
  name: 'Template3',
  data() {
    return {
        response: null,
        name: "Langston"
    }
  },
  methods: {
    async getContent() {
      // Store the 'homepage' document from the Prismic API in a constant called 'response'
      const response = await this.$prismic.client.getSingle('homepage');
      // and assign the response to the "response" data property
      this.response = response;
    }
  },
  created() {
    // When this component is created, call the method to get the prismic content
    this.getContent();
  }
}
</script>
