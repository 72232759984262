<template>
    <div class="text-white">
        {{response.data.phone_number[0].text}}<br/>
        {{response.data.email[0].text}}<br/>
        {{response.data.website_url[0].text}}<br/>
    </div>
</template>

<script>

export default{
    name: "Contact",
    data() {
    return {
        response: null
    }
  },
  methods: {
    async getContent() {
      // Store the 'homepage' document from the Prismic API in a constant called 'response'
      const response = await this.$prismic.client.getSingle('contact_page');
      // and assign the response to the "response" data property
      this.response = response;
    }
  },
  created() {
    // When this component is created, call the method to get the prismic content
    this.getContent();
  }
}
</script>