import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrismicVue from '@prismicio/vue'
import linkResolver from './link-resolver' // Update this path if necessary
// import './assets/tailwind.css'

const accessToken = '' // Leave empty if your repository is public
const endpoint = 'https://baber-template1.prismic.io/api/v2' // Use your repository name

Vue.config.productionTip = false
// Register plugin
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
